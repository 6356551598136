// const Homepage = () => import('../../../components/homepage/Homepage')
// // const RouteView = {
// //   name: 'RouteView',
// //   render: (h) => h('router-view')
// // }
// const allRouter = [
//     {
//         path: '/',
//         component: Homepage,
//         children: [
//           {
//             path: '/home',
//             meta: {
//               title: '首页',
//               name: 'home',
//             },
//             component: () => import(/* webpackChunkName: "commodity" */ '../../../views/home.vue')
//           },
//           {
//             path: '/dataProfiling',
//             component: () => import('@/views/dataProfiling/index'),
//             name: '数据概况',
//             meta: {
//                 title: '数据概况',
//             }
//         },
//         {
//             path: '/roleConfig',
//             component: () => import('@/views/roleConfig/index'),
//             name: 'AI角色配置',
//             meta: {
//                 title: 'AI角色配置',
//             }
//         },
//         {
//             path: '/operationalConfig',
//             component: () => import('@/views/operationalConfig/index'),
//             name: '运营常量配置',
//             meta: {
//                 title: '运营常量配置',
//             }
//         },
        
//         {
//             path: '/userManage',
//             component: () => import('@/views/userManage/index'),
//             name: '用户管理',
//             meta: {
//                 title: '用户管理',
//             }
//         },

//         {
//             path: '/promotion/channel',
//             component: () => import('@/views/promotion/channel'),
//             name: '渠道列表',
//             meta: {
//                 title: '渠道列表',
//             }
//         },

//         {
//             path: '/promotion/role',
//             component: () => import('@/views/promotion/role'),
//             name: '推广角色',
//             meta: {
//                 title: '推广角色',
//             }
//         },

//         {
//             path: '/promotion/overview',
//             component: () => import('@/views/promotion/overview'),
//             name: '推广概览',
//             meta: {
//                 title: '推广概览',
//             }
//         },
//         {
//             path: '/promotion/channelRole',
//             component: () => import('@/views/promotion/channelRole'),
//             name: '渠道角色管理',
//             meta: {
//                 title: '渠道角色管理',
//             }
//         },
          
//         ]
//       },
// ]

export default []
